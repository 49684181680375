import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'

import {
    ToastContainer,
} from 'react-toastify';

const UserPages = lazy(() => import('../../Pages/UserPages'));
const Applications = lazy(() => import('../../Pages/Applications'));
const Dashboards = lazy(() => import('../../Pages/Dashboards'));

const Widgets = lazy(() => import('../../Pages/Widgets'));
const Elements = lazy(() => import('../../Pages/Elements'));
const Components = lazy(() => import('../../Pages/Components'));
const Charts = lazy(() => import('../../Pages/Charts'));
const Forms = lazy(() => import('../../Pages/Forms'));
const Tables = lazy(() => import('../../Pages/Tables'));
const Organization = lazy(() => import('../../Pages/Organization'));
const Settings = lazy(() => import('../../Pages/Settings'));
const recruitment = lazy(() => import('../../Pages/Recruitment'));
const jobpage = lazy(() => import('../../Pages/Jobs'));
const recruitmentForm = lazy(() => import('../../Pages/Recruitment_form'));


const AppMain = () => {

    return (
        <Fragment>


            {/* Jobs */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load Job Page
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/Jobs" component={jobpage}/>
            </Suspense>
            {/* Auth */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load Login Page
                            {/*<small>Because this is a demonstration, we load at once all the Dashboards examples. This*/}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/Auth" component={UserPages}/>
            </Suspense>


            {/* Dashboards */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all the Dashboards Data
                            {/*<small>Because this is a demonstration, we load at once all the Dashboards examples. This*/}

                        </h6>
                    </div>
                </div>
            }>
                <Route path="/dashboards" component={Dashboards}/>
            </Suspense>

            {/* Organization */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all Organization Data
                            {/*<small>Because this is a demonstration, we load at once all the Dashboards examples. This*/}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/Organization" component={Organization}/>
            </Suspense>

            {/* Recruitment */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all Recruitment Data
                            {/*<small>Because this is a demonstration, we load at once all the Dashboards examples. This*/}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/Recruitment" component={recruitment}/>
            </Suspense>
            {/* Settings */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all Settings
                            {/*<small>Because this is a demonstration, we load at once all the Dashboards examples. This*/}

                        </h6>
                    </div>
                </div>
            }>
                <Route path="/settings" component={Settings}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load Recruitment Form
                            {/*<small>Because this is a demonstration, we load at once all the Dashboards examples. This*/}
                            {/*wouldn't happen in a real live app!</small>*/}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/recruitmentForm" component={recruitmentForm}/>
            </Suspense>


            {(() => {
                if (localStorage.getItem('role') === 'Owner') {
                    if (localStorage.getItem('company_setup_completed') !== '1') {
                        return <Route exact path="/" render={() => (
                            <Redirect to="/dashboards/CompanySetup"/>
                        )}/>;
                    } else {
                        return <Route exact path="/" render={() => (
                            <Redirect to="/dashboards/Company"/>
                        )}/>;
                    }
                } else if (localStorage.getItem('role') === 'System Admin') {
                    return <Route exact path="/" render={() => (
                        <Redirect to="/dashboards/Super"/>
                    )}/>;

                } else if (localStorage.getItem('role') === 'Employee') {
                    return <Route exact path="/" render={() => (
                        <Redirect to="/dashboards/employee"/>
                    )}/>;
                } else if (localStorage.getItem('role') === 'HR Manager') {
                    return <Route exact path="/" render={() => (
                        <Redirect to="/dashboards/HRManager"/>
                    )}/>;
                } else {

                    return <Route exact path="/" render={() => (
                        <Redirect to="/dashboards/CRM"/>
                    )}/>;
                }
            })()}
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;