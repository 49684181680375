//const BASE_URL = process.env.NODE_ENV === "production" ? `${window.location.protocol}//167.99.238.154/hrms/API` : "localhost/hrms"

if (window.location.protocol === 'http:' || window.location.protocol === "http:") {
  // global.BASE_URL = 'http://167.99.238.154/hrmis/API';
  global.BASE_URL = 'https://logicielity.com/hrmis/API';
    // global.BASE_URL = 'http://192.168.18.18/hrmis/API';


} else {
   global.BASE_URL = 'https://logicielity.com/hrmis/API';
  //  global.BASE_URL = 'https://167.99.238.154/hrmis/API';
}

//export { BASE_URL }
